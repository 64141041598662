// import { LoadingState } from '@powerplay/core-minigames-ui-ssm'
import {
  settings,
  SettingsTypes,
  timeManager
} from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '../phases/DisciplinePhasesManager'
import { DisciplinePhases } from '../types'
import { player } from '../entities/athlete/player'
import {
  timeState,
  speedmeterState,
  directionsState
} from '@/stores'
import {
  fpsLookerState,
  gameSettingsState
} from '@powerplay/core-minigames-ui-ssm'
import { getActivePinia } from 'pinia'

export interface FpsDataVuex {
  averageFps: number
  actualFps: number
  actualAverageFps: number
}

export interface SpeedData {
  visible: boolean
  speed: string | number
}

export class StateManager {

  public getFpsStarted(): boolean {

    return fpsLookerState().isStarted ?? false

  }

  public setFpsData(fpsData: FpsDataVuex): void {

    fpsLookerState().$patch(fpsData)

  }

  public allowDirectionState(): void {

    directionsState().show = true

  }

  public setBeforeGameStartPhase(): void {

    gameSettingsState().$patch({
      graphicsSettings: settings.getSetting(SettingsTypes.quality),
      graphicsAuto: settings.getSetting(SettingsTypes.qualityAuto) === 1,
      volume: settings.getSetting(SettingsTypes.sounds) === 1,
      isLeft: settings.getSetting(SettingsTypes.isLeft) === 1,
      controlsType: settings.getSetting(SettingsTypes.controlsType)
    })

  }

  public setUpdateTimeState(): void {

    let time = '0.0'
    if (disciplinePhasesManager.actualPhase >= DisciplinePhases.finish) {

      time = timeManager.getTimeInFormatFromSeconds(Math.ceil(player.finalTime * 100) / 100)

    } else {

      time = timeManager.getGameTimeWithPenaltyInFormat(1)

    }

    timeState().time = time


  }

  public hideSpeedVisibility(): void {

    speedmeterState().visible = false

  }

  public updateSpeedData(speedData: SpeedData): void {

    speedmeterState().speed = Number(speedData.speed)
    speedmeterState().visible = speedData.visible

  }

  /**
   * Resetovanie pinie
   */
  public resetPinia(): void {

    const exceptions = ['tutorialState', 'inputsState', 'mobileState', 'loadingState', 'gameSettingsState']

    // eslint-disable-next-line
    // @ts-ignore
    getActivePinia()?._s.forEach((s: Store) => {

      if (!exceptions.includes(s.$id)) s.$reset()

    })

  }

}

export const stateManager = new StateManager()
